<template>
  <div class="h-100">
    <v-sheet class="tabs" elevation="0">
      <v-tabs class="custom-tabs">
        <v-tab :to="`/bim-compare/${$route.params.exid}/compare`" nuxt>
          compare
        </v-tab>
        <v-tab :to="`/bim-compare/${$route.params.exid}/layers`" nuxt>
          layers
        </v-tab>
        <v-tab :to="`/bim-compare/${$route.params.exid}/ITWinBimCompare`" nuxt>
          ITwin compare
        </v-tab>
        <v-btn
          class="close-icon mt-n2"
          color="primary"
          icon
          dark
          large
          @click="goBack"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-tabs>
      <v-divider></v-divider>
    </v-sheet>

    <NuxtChild />
  </div>
</template>

<script>
export default {
  name: "BimCamera",
  async asyncData({ route, params, redirect }) {
    if (route.name === "bim-compare-exid") {
      redirect(`/bim-compare/${params.exid}/compare`)
    }
  },
  methods: {
    goBack() {
      this.$router.push("/bim-compare")
    },
  },
}
</script>

<style scoped>
.tabs {
  position: relative;
  z-index: 99;
  box-shadow: 0 14px 10px -14px #0000002e;
}
</style>
